function applyMandatoryRatingAgencies(venueWineObject, ratingAgencies) {
  let mandatoryRatingAgencies = getMandatoryRatingAgencies(ratingAgencies)
  if ( venueWineObject.attributes.ratings == null) {
    venueWineObject.attributes.ratings = []
  }
  for (let a = 0; a < mandatoryRatingAgencies.length; a++) {
    const ratingAgencyId = mandatoryRatingAgencies[a].id;
    const defaultRatingValue = mandatoryRatingAgencies[a].default
    venueWineObject.attributes.ratings.push({
        value: defaultRatingValue,
        ratingAgency: ratingAgencyId
    })
  }
}

function getMandatoryRatingAgencies(ratingAgencies) {
  var mandatoryRatingAgencies = []
  ratingAgencies.data.forEach((ratingAgency) => {
              if(ratingAgency.attributes.mandatory === true) {
                  var mandatoryRatingAgency = {
                      id: ratingAgency.id,
                      default: ratingAgency.attributes.default,
                  }
                  mandatoryRatingAgencies.push(mandatoryRatingAgency)
              }
  })
  return mandatoryRatingAgencies
}

function  calcPurchasePriceAvg(purchasePrices) {
  var priceSum = 0.0;
  if (purchasePrices != null && purchasePrices.length > 0) {
    purchasePrices.forEach((element) => {
      priceSum += element.price;
    });
    priceSum = priceSum / purchasePrices.length;
    priceSum = Math.round(priceSum * 100) / 100;
  }
  return priceSum;
}

function addSupplierAndPrice(venueWine, price, supplier) {
  if (venueWine.attributes["purchasePrices"] == null) {
    venueWine.attributes["purchasePrices"] = []
  }
  const foundPurchasePrice = venueWine.attributes["purchasePrices"].find(
    (pp) => pp.price === price
  );
  if (!foundPurchasePrice) {
    var purchasePriceBody = {
      price: price,
      wineSupplier: supplier["id"],
    };
    venueWine.attributes["purchasePrices"] = []
    venueWine.attributes["purchasePrices"].push(purchasePriceBody);
  } else {
    foundPurchasePrice.wineSupplier = supplier["id"];
  }

  venueWine.attributes["purchasePriceAvg"] = calcPurchasePriceAvg(
    venueWine["purchasePrices"]
  )
  return venueWine
}

function createCSVArrayForExport(response){
  var csvArray = []
    response.forEach((venueWine) => {
      var row = {
        Weingut: venueWine.attributes.wine.data.attributes.winery.data.attributes.title,
        Weinname: venueWine.attributes.wine.data.attributes.title,
        Jahrgang: venueWine.attributes.year,
        Region: venueWine.attributes.wine.data.attributes.region.data.attributes.title,
        Land: venueWine.attributes.wine.data.attributes.land.data.attributes.title,
        Farbe: venueWine.attributes.wine.data.attributes.wineType.data.attributes.title,
        Rebsorte: venueWine.attributes.wine.data.attributes.grapeVariety.data.attributes.title,
        Anzahl: venueWine.attributes.amount,
        EK: venueWine.attributes.purchasePriceAvg,
        VK: venueWine.attributes.sellingPrice,
        Flaschengröße: venueWine.attributes.bottleSize.data.attributes.amount,
        Verkostungsnotiz: venueWine.attributes.comments,
        Bezugsquelle: venueWine.attributes.purchasePrices && venueWine.attributes.purchasePrices.length > 0 ? venueWine.attributes.purchasePrices[0].wineSupplier.data.attributes.title : 'nicht vorhanden',
        Regal: venueWine.attributes.shelfNumber
      }
      csvArray.push(row)
    })
    return csvArray
}

function writeArrayToCSVDownload(array, fileName, papaParse) {
  var csv = papaParse.unparse(array);
  //var csv = this.$papa.unparse(array);
  //var venueName = this.getVenue.attributes.title + '_' + DateTimeService.dateToFileString(new Date())
  var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
  var csvURL =  null;
  if (navigator.msSaveBlob)
  {
      csvURL = navigator.msSaveBlob(csvData, fileName + '.csv');
  }
  else
  {
      csvURL = window.URL.createObjectURL(csvData);
  }

  var tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', fileName + '.csv');
  tempLink.click();
}

function getWineNameNullChecked(title, grapeVariety) {
  if (title == null || title == "" || title.trim() == "") {
    return grapeVariety
  }
  return title
}

export {
  applyMandatoryRatingAgencies,
  getMandatoryRatingAgencies,
  calcPurchasePriceAvg,
  addSupplierAndPrice,
  createCSVArrayForExport,
  writeArrayToCSVDownload,
  getWineNameNullChecked
}